// // src/components/Services.js
// import React from 'react';
// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import { styled } from '@mui/material/styles';

// const ServiceCard = styled(Paper)(({ theme }) => ({
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//     height: '100%',
// }));

// const services = [
//     { name: 'Threading', image: 'threading.jpg' },
//     { name: 'Tinting', image: 'tinting.jpg' },
//     { name: 'Henna Designs', image: 'henna_design.jpg' },
//     { name: 'Indian Head Massage', image: 'head_message.jpg' },
//     { name: 'Lash Perming and Lifting', image: 'lash.jpg' },
//     { name: 'Lash Extensions', image: 'last_ext.jpg' },
//     { name: 'Brow Lamination', image: 'bro_lam.jpg' },
//     { name: 'Face Waxing', image: 'face_wax.jpeg' },
// ];

// function Services() {
//     return (
//         <Container>
//             <Typography variant="h4" component="h2" gutterBottom>
//                 Our Services
//             </Typography>
//             <Grid container spacing={3}>
//                 {services.map((service, index) => (
//                     <Grid item xs={12} sm={6} md={4} key={index}>
//                         <ServiceCard>
//                             <img src={service.image} alt={service.name} style={{ width: '100%', height: 'auto' }} />
//                             <Typography variant="h6" component="h3">
//                                 {service.name}
//                             </Typography>
//                         </ServiceCard>
//                     </Grid>
//                 ))}
//             </Grid>
//         </Container>
//     );
// }

// export default Services;


import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const ServiceCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
}));

const services = [
    { name: 'Threading', image: 'threading.jpg', description: "Experience the precision and elegance of perfectly shaped eyebrows with our expert threading services – because you deserve to look your best!" },
    { name: 'Tinting', image: 'tinting.jpg', description: "Enhance the natural beauty of your brows and lashes with our professional tinting services – achieve a bold, defined look effortlessly!" },
    // { name: 'Henna Designs', image: 'henna_design.jpg', description: "Adorn your skin with intricate, stunning henna designs – perfect for any occasion and crafted with care to leave a lasting impression!" },
    { name: 'Indian Head Massage', image: 'head_message.jpg', description: "Indulge in the ultimate relaxation with our soothing Indian head massage – rejuvenate your mind with this ancient healing technique!" },
    { name: 'Lash Perming and Lifting', image: 'lash.jpg', description: "Achieve beautifully curled and lifted lashes with our lash perming and lifting services – wake up to effortlessly glamorous eyes every day!" },
    { name: 'Lash Extensions', image: 'last_ext.jpg', description: "Get the long, voluminous lashes you've always dreamed of with our expert lash extensions – enhancing your natural beauty effortlessly!" },
    { name: 'Brow Lamination', image: 'bro_lam.jpg', description: "Transform your brows with our brow lamination service – achieve a fuller, more defined look that frames your face beautifully!" },
    { name: 'Face Waxing', image: 'face_wax.jpg', description: "Reveal smooth, radiant skin with our professional face waxing services – for a flawlessly fresh and glowing complexion!" },
];

function Services() {
    return (
        <Container id="services" >
            <Typography marginTop={10} variant="h3" component="h2" align="center" gutterBottom>
                Our Services
            </Typography>
            <Typography variant="body1" align="center" paragraph>
                Arch Eyebrows will provide you with the most beautiful Eyelash Extensions, Eyelash Perming, Brow lamination, Brow Henna, Threading for your eyebrows, and Tint them to match your hair, or any other colour you like. We also provide facial services. Come and visit us in one of our many stores throughout Queensland.
            </Typography>
            <Grid container spacing={3} sx={{ mt: 4 }}>
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <ServiceCard>
                            <img src={service.image} alt={service.name} style={{ width: '100%', height: 'auto' }} />
                            <Typography variant="h6" component="h3">
                                {service.name}
                            </Typography>
                            <Typography >
                                {service.description}
                            </Typography>

                        </ServiceCard>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Services;
