import React, { useRef, useState } from 'react';
import {
    Container, Grid, Typography, TextField, Button, Paper, Box,
    Select, MenuItem, FormControl, InputLabel, FormControlLabel,
    Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails ,Modal
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import emailjs from 'emailjs-com';

const servicesOptions = {
    Threading: {
        "Eyebrow Shaping": 20,
        "Upperlip": 10,
        "Lowerlip": 5,
        "Chin": 10,
        "Forehead": 10,
        "Mono Brow": 5,
        "Sides": 15,
        "Cheeks": 10,
        "Full Face (Includes all of the above)": 65,
        "Neck": 15,
        "Men's Eyebrow Shaping": 25,
    },
    "Threading packages": {
        "Eyebrow & Upperlip": 25,
        "Eyebrow,Upperlip & Chin": 35,
        "Eyebrow,Upperlip,LowerLip & Chin": 40,
    },
    Tinting: {
        "Eyebrow": 15,
        "Eyelash": 20,
        "Eyebrow & Eyelash": 35,
        "Men's Eyebrow": 20,
    },
    "Threading & Tinting Packages": {
        "Brow Shaping, Brow & Lash Tinting": 50,
        "Brow Shaping & Brow Tinting": 35,
        "Brow Shaping, Upperlip & Brow Tinting": 45,
        "Men's Eyebrow Shaping & Tinting": 40,
    },
    "Brow Henna Tinting Packages": {
        "Brow Henna Tinting": 35,
        "Eyebrow Threading & Brow Henna Tinting": 50,
        "Eyebrow Shaping, Brow Henna Tinting & Eyelash Tinting": 65,
    },
    "Hybrid Brow Tint": {
        "Hybrid Brow Tint": 35,
        "Hybrid Brow Shaping": 50,
    },
    "Face Waxing": {
        "Eyebrows": 20,
        "Mono Brow": 5,
        "Upperlip": 10,
        "Chin": 10,
        "Cheeks": 10,
        "Sides": 15,
        "Full Face (Includes all of above)": 65,
        "Ears": 15,
        "Nosetails": 20,
        "Neck": 15,
    },
    "Brow Lamination": {
        "Brow Lamination": 65,
        "Brow Lamination & Shaping": 75,
        "Brow Lamination,Shaping & Tinting": 85,
    },
    "Lash Perming & Lifting": {
        "Lash Lift/Perm": 65,
        "Lash Lift & Tinting": 75,
    },
    "Lash Extensions": {
        "Full Set": 120,
        "Half Set": 80,
        "Refills": 55,
        "Removals": 25,
    },
};

const BookingForm = () => {
    const navigate = useNavigate();
    const nameRef = useRef('');
    const emailRef = useRef('');
    const phoneRef = useRef('');
    const selectedServiceRef = useRef('');
    const selectedOptionsRef = useRef({});
    const selectedDateRef = useRef(dayjs());
    const selectedTimeRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [errors, setErrors] = useState({
        name: 'Name is required',
        email: 'Email is required',
        phone: 'Phone number is required',
        service: 'Please select a service',
        options: 'Please select at least one option',
        date: 'Date is required',
        time: 'Time is required',
    });
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    // Validation functions
    const validateName = () => {
        if (!nameRef.current.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: '',
            }));
        }
    };

    const validateEmail = () => {
        if (!emailRef.current.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Email is required',
            }));
        } else if (!/\S+@\S+\.\S+/.test(emailRef.current)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Email is invalid',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: '',
            }));
        }
    };

    const validatePhone = () => {
        if (!phoneRef.current.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: 'Phone number is required',
            }));
        }  else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: '',
            }));
        }
    };

    const validateService = () => {
        if (!selectedServiceRef.current) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                service: 'Please select a service',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                service: '',
            }));
        }
    };

    const validateOptions = () => {
        if (Object.keys(selectedOptionsRef.current).length === 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                options: 'Please select at least one option',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                options: '',
            }));
        }
    };

    const validateDateAndTime = () => {
        if (!expanded) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                date: 'Date and Time are required',
                // time: 'Date and Time are required',
            }));
        } else {
            if (!selectedDateRef.current) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    date: 'Date is required',
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    date: '',
                }));
            }
            if (!selectedTimeRef.current) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    time: 'Time is required',
                }));
            } else {
                // Check if time is 6 PM and adjust date validation
                const isSixPM = selectedTimeRef.current && dayjs(selectedTimeRef.current).hour() === 18;
                if (isSixPM) {
                    // Check if date is next day
                    const isNextDay = selectedDateRef.current && dayjs(selectedDateRef.current).isAfter(dayjs(), 'day');
                    if (!isNextDay) {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            time: 'Time is 6 PM, please select the next day for booking',
                        }));
                    } else {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            time: '',
                        }));
                    }
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        time: '',
                    }));
                }
            }
        }
    };

    // Handle input change and validate dynamically
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'name':
                nameRef.current = value;
                validateName();
                break;
            case 'email':
                emailRef.current = value;
                validateEmail();
                break;
            case 'phone':
                phoneRef.current = value;
                  validatePhone();
                break;
            default:
                break;
        }
    };

    const handleServiceChange = (e) => {
        const selected = e.target.value;
        selectedServiceRef.current = selected;
        // Clear the error message for service selection
        setErrors((prevErrors) => ({
            ...prevErrors,
            service: '',
        }));
    };

    const handleOptionChange = (e, service) => {
        const value = e.target.value;
        selectedOptionsRef.current = {
            ...selectedOptionsRef.current,
            [service]: selectedOptionsRef.current[service]?.includes(value)
                ? selectedOptionsRef.current[service].filter((option) => option !== value)
                : [...(selectedOptionsRef.current[service] || []), value],
        };
        // Clear the error message for options selection
        setErrors((prevErrors) => ({
            ...prevErrors,
            options: '',
        }));
        validateOptions();
    };

    const handleDeleteOption = (service, option) => {
        selectedOptionsRef.current = {
            ...selectedOptionsRef.current,
            [service]: selectedOptionsRef.current[service].filter((opt) => opt !== option),
        };
        validateOptions();
    };

    const handleDateChange = (date) => {
        selectedDateRef.current = date;
        validateDateAndTime();
    };

    const handleTimeChange = (time) => {
        selectedTimeRef.current = time;
        validateDateAndTime();
    };

    const handleBookingSubmit = (e) => {
        e.preventDefault();

        // Validate form fields on submit
        validateName();
        validateEmail();
         validatePhone();
        validateService();
        validateOptions();
        validateDateAndTime();

        // Check if form is valid
        const hasErrors = Object.values(errors).some((error) => error !== '');
        if (hasErrors) {
            return;
        }

        // If form is valid, send email
        sendEmail();
    };

    const today = dayjs();
    const nineAM = dayjs().set('hour', 9).startOf('hour');
    const fivePM = dayjs().set('hour', 17).startOf('hour');

    const calculateTotalPrice = () => {
        return Object.keys(selectedOptionsRef.current).reduce((total, service) => {
            return (
                total +
                selectedOptionsRef.current[service].reduce((serviceTotal, option) => {
                    return serviceTotal + servicesOptions[service][option];
                }, 0)
            );
        }, 0);
    };

    const renderSelectedOptions = () => {
        return Object.keys(selectedOptionsRef.current).map((service) =>
            selectedOptionsRef.current[service].map((option) => (
                <Box
                    key={`${service}-${option}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1}
                >
                    <Typography variant="body1">
                        {service} - {option}: ${servicesOptions[service][option]}
                    </Typography>
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteOption(service, option)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))
        ).flat();
    };
    const handleCloseSuccessModal = () => {
        setSuccessModalOpen(false);
        navigate('/'); // Navigate to home page or success message page
    };

    const sendEmail = () => {
        // // Prepare the template parameters
        // const templateParams = {
        //     name: nameRef.current,
        //     email: emailRef.current,
        //     phone: phoneRef.current,
        //     service: selectedServiceRef.current,
        //     message: JSON.stringify(selectedOptionsRef.current, null, 2), // Convert options object to string
        // };

        // Prepare the template parameters
const selectedOptions = selectedOptionsRef.current;
const formattedOptions = Object.keys(selectedOptions).map((service) => {
    return `${service}:\n${selectedOptions[service].map(option => `- ${option}`).join('\n')}`;
}).join('\n\n');

const templateParams = {
    name: nameRef.current,
    email: emailRef.current,
    phone: phoneRef.current,
    service: selectedServiceRef.current,
    message: formattedOptions,
    date: dayjs(selectedDateRef.current).format('YYYY-MM-DD'), // Format as desired
    time: dayjs(selectedTimeRef.current).format('HH:mm'), // Format as desired (24-hour format)
};

        // Send email using emailjs
        emailjs.send('service_07pnf9r', 'template_ufo7uee', templateParams, '7IMT03FTNxZLjoVyZ')
            .then((response) => {
                console.log('Email sent successfully!', response.text);
                // Reset form after successful submission
                nameRef.current = '';
                emailRef.current = '';
                phoneRef.current = '';
                selectedServiceRef.current = '';
                selectedOptionsRef.current = {};
                selectedDateRef.current = dayjs();
                selectedTimeRef.current = null;
                setExpanded(false);
                setErrors({
                    name: '',
                    email: '',
                    phone: '',
                    service: '',
                    options: '',
                    date: '',
                    time: '',
                });

                setSuccessModalOpen(true);

                // // Navigate or show success message
                // navigate('/'); // Navigate to success page or show success message
            })
            .catch((error) => {
                console.error('Failed to send email. Error:', error.text);
                // Handle error scenario (show error message, etc.)
                alert('Failed to send email. Please try again later.');
            });
    };

    const handleCancel = () => {
        navigate('/');
    };
    return (
        <Container sx={{ mt: 15, mb: 2 }}>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
                Book an Appointment with Us :D
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <form onSubmit={handleBookingSubmit}>
                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                onChange={handleInputChange}
                                error={!!errors.name}
                                helperText={errors.name}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                type="email"
                                onChange={handleInputChange}
                                error={!!errors.email}
                                helperText={errors.email}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="Phone"
                                name="phone"
                                type="tel"
                                onChange={handleInputChange}
                                error={!!errors.phone}
                                helperText={errors.phone}
                                margin="normal"
                                inputProps={{
                                    pattern: '^(\+?61|0)\\s?[2-9]\\d{3}\\s?\\d{3}\\s?\\d{3}$',
                                    title: 'Phone number is invalid (must match Australian formats)',
                                }}
                            />
                            <FormControl fullWidth margin="normal" error={!!errors.service}>
                                <InputLabel>Choose Service</InputLabel>
                                <Select
                                    value={selectedServiceRef.current}
                                    onChange={handleServiceChange}
                                    name="service"
                                    label="Choose Service"
                                >
                                    {Object.keys(servicesOptions).map((service) => (
                                        <MenuItem key={service} value={service}>
                                            {service}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.service && (
                                    <Typography variant="caption" color="error">
                                        {errors.service}
                                    </Typography>
                                )}
                            </FormControl>
                            {selectedServiceRef.current && (
                                <FormControl fullWidth margin="normal">
                                    <Typography variant="h6">Select Options</Typography>
                                    {Object.keys(servicesOptions[selectedServiceRef.current]).map(
                                        (option) => (
                                            <FormControlLabel
                                                key={option}
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            selectedOptionsRef.current[selectedServiceRef.current]?.includes(
                                                                option
                                                            ) || false
                                                        }
                                                        onChange={(e) =>
                                                            handleOptionChange(
                                                                e,
                                                                selectedServiceRef.current
                                                            )
                                                        }
                                                        value={option}
                                                    />
                                                }
                                                label={`${option} - $${servicesOptions[selectedServiceRef.current][option]}`}
                                            />
                                        )
                                    )}
                                    {errors.options && (
                                        <Typography variant="caption" color="error">
                                            {errors.options}
                                        </Typography>
                                    )}
                                </FormControl>
                            )}
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="h6">Selected Services:</Typography>
                                {renderSelectedOptions()}
                            </Box>
                            <Typography variant="h6">Total Price: ${calculateTotalPrice()}</Typography>
                            <Box sx={{ mt: 2 }}>
                                <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ mb: 2 }} variant="h6">
                                            Select Date and Time :
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ display: 'flex', alignItems: 'center' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Date"
                                                value={selectedDateRef.current}
                                                onChange={handleDateChange}
                                                sx={{ flex: 1, marginRight: 2 }}
                                                minDate={today}
                                            />
                                            <TimePicker
                                                ampm={false}
                                                label="Time"
                                                minTime={nineAM}
                                                maxTime={fivePM}
                                                value={selectedTimeRef.current}
                                                onChange={handleTimeChange}
                                                sx={{ flex: 1 }}
                                            />
                                        </LocalizationProvider>
                                    </AccordionDetails>
                                </Accordion>
                                {(errors.date || errors.time) && (
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="caption" color="error">
                                            {errors.date && `${errors.date} `}
                                            {errors.time}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ mt: 2 }}>
                            <Button sx={{ mr: 2 }}variant="outlined" color="primary" type="cancel" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" type="submit">
                                    Submit
                                </Button>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                               
                            </Box>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
             {/* Success Modal */}
          {/* Success Modal */}
          <Modal
                open={successModalOpen}
                onClose={handleCloseSuccessModal}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper sx={{ p: 4, maxWidth: 400 }}>
                    <Typography variant="h5" component="h2" align="center" gutterBottom>
                        Booking Successful!
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        Thank you for booking with Arch Eye.
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        Our representative will contact you shortly.
                    </Typography>
                    <Typography variant="body1" align="center">
                        See you at the parlour! 😊🎉
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" color="primary" onClick={handleCloseSuccessModal}>
                            Close
                        </Button>
                    </Box>
                </Paper>
            </Modal>
        </Container>
    );
};

export default BookingForm;
