import React from 'react';
import { Container, Grid, Typography, TextField, Button, Paper, Box } from '@mui/material';
import emailjs from 'emailjs-com';
import LocationMap from './LocationMap'; // Import the LocationMap component

const GetInTouch = () => {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_07pnf9r', 'template_7p3745s', e.target, '7IMT03FTNxZLjoVyZ')
            .then((result) => {
                console.log(result.text);
                alert('Email sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send email.');
            });

        e.target.reset();
    };

    return (
        <Container sx={{ mt: 8, mb: 2 }} id="contact">
            <Typography variant="h4" component="h2" align="center" gutterBottom>
                Get in Touch
            </Typography>
            <Typography variant="body1" align="center" paragraph>
                Contact us to ask about our services and enquiries.
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" component="h3" gutterBottom>
                            Our Location
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {/* <strong>Location 1:</strong><br /> */}
                            Pavilions Palm Beach<br />
                            4/6 Fifth Ave, Palm Beach QLD 4221, Australia <br />
                            Phone: +61 466 967 866
                        </Typography>

                         {/* <Typography variant="body2" paragraph>
                            <strong>Location 2:</strong><br />
                            456 Side St, Townsville, QLD 4001<br />
                            Email: location2@example.com<br />
                            Phone: (234) 567-8901
                        </Typography> */}
                        {/* Add more locations as needed */}
                        <LocationMap /> {/* Add the map below the location */}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <form onSubmit={sendEmail}>
                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                margin="normal"
                                required
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                type="email"
                                margin="normal"
                                required
                            />
                            <TextField
                                fullWidth
                                label="Phone"
                                name="phone"
                                type="tel"
                                margin="normal"
                                required
                            />
                            <TextField
                                fullWidth
                                label="Message"
                                name="message"
                                margin="normal"
                                multiline
                                rows={4}
                                required
                            />
                            <Box sx={{ mt: 2 }}>
                                <Button variant="contained" color="primary" type="submit">
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default GetInTouch;
