import React from 'react';

const LocationMap = () => {
    return (
        <div style={{ width: '80%', height: '305px' }}>
            <iframe
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14075.211123765193!2d153.472893!3d-28.1220399!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91036b1a27e1f1%3A0x84fa9b6d39e155c9!2sArch%20Eyebrow%20Bar!5e0!3m2!1sen!2sin!4v1719748325828!5m2!1sen!2sin">
                allowFullScreen
            </iframe>
        </div>
    );
};

export default LocationMap;
