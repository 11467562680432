import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import logo from '../logo.svg';

function HeaderComp({ showButtons = true }) {
    return (
        <AppBar position="fixed" style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1, color: 'white' }}>
                    Arch Eyebrow Bar
                </Typography>
                {showButtons && (
                    <>
                        <ScrollLink to="home" smooth={true} duration={500}>
                            <Button color="inherit">Home</Button>
                        </ScrollLink>
                        <ScrollLink to="services" smooth={true} duration={500}>
                            <Button color="inherit">Services</Button>
                        </ScrollLink>
                        <ScrollLink to="contact" smooth={true} duration={500}>
                            <Button color="inherit">Contact</Button>
                        </ScrollLink>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default HeaderComp;
