import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
    const navigate = useNavigate();

    const handleBookAppointment = () => {
        navigate('/book-appointment');
    };

    return (
        <Box id="home"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh',
                color: 'white',
                textAlign: 'center',
                backgroundImage: `url(/mainImage.jpg)`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                padding: '20px',
                boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
                paddingTop: '64px', // Add top padding to offset the AppBar height
                marginTop:"60px"
            }}
        >
            <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                Arch Eyebrow Bar
            </Typography>
            <Typography variant="h5" sx={{ fontStyle: 'italic', mb: 1 }}>
                Established in 2021
            </Typography>
            <Typography variant="h6">
                "Transforming your eyebrows, transforming your look."
            </Typography>
            <Button
                variant="contained"
                sx={{ mt: 3, px: 4, py: 2, backgroundColor: '#FF4081', color: 'white', '&:hover': { backgroundColor: '#FF4081' } }}
                onClick={handleBookAppointment}
            >
                Book an Appointment
            </Button>
        </Box>
    );
};

export default HeroSection;
