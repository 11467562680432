import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import HeaderComp from './components/HeaderComp';
import HeroSection from './components/HeroSection';
import Services from './components/Services';
import GetInTouch from './components/GetinTouch';
import Footer from './components/Footer';
import BookingForm from './components/Bookingform';
// import GoogleReviews from './components/GoogleReviews';
//we will use this later

function App() {
    return (
        <Router>
            <CssBaseline />
            <MainContent />
            <Footer />
        </Router>
    );
}

const MainContent = () => {
    const location = useLocation();

    return (
        <>
            <HeaderComp showButtons={!location.pathname.includes('/book-appointment')} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/book-appointment" element={<BookingForm />} />
            </Routes>
        </>
    );
};

const Home = () => {
    return (
        <>
            <HeroSection />
            <Services />
            <GetInTouch />
            {/* <GoogleReviews/> */}
        </>
    );
};

export default App;
