import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

function Footer() {
    return (
        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: 'black', color: 'white', textAlign: 'center' }}>
            <Container maxWidth="sm">
                <Typography variant="body1">
                    Arch Eyebrow Bar - Since 2021
                </Typography>
                <Typography variant="body2" >
                    {'© '}
                    {new Date().getFullYear()}
                    {' '}
                    Arch Eyebrow Bar. All rights reserved.
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="caption">
                    Handled by HarshOm & Co
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;
